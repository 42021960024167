import * as Sentry from '@sentry/react';
import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { MusherState } from '../../@types/musher';

const initialState: MusherState = {
  isLoading: false,
  statusLoading: false,
  error: false,
  musherList: {
    totalCount: 0,
    data: [],
    page: 0,
    size: 0
  },
  questionList: {
    metaData: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0
    } ,
    totalItems: 0,
    data: [],
    page: 0,
    size: 0
  },
  questionData: {
   status:"",
   data:{
    answer:"",
    correctAnswer:""
   }
  },
  musherRequestList: [],
  musher: null,
  isMusherDeleted: false,
  isMusherCreated: false,
  musherStatus: {},
  questionsStatus: {},
  musherAvailabilityStatus: {},
  availableMusher: [],
  editQestionStatus:{
    status: ''
  },
  client: {
    metaData: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0
    } ,
    totalItems: 0,
    data: [],
    page: 0,
    size: 0
  },
  
};

const slice = createSlice({
  name: 'musher',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    statusLoading(state) {
      state.statusLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET Musher LIST
    getMusherListSuccess(state, action) {
      state.isLoading = false;
      state.musherList = action.payload;
    },

     // GET Question LIST
     getQuestionListSuccess(state, action) {
      state.isLoading = false;
      state.questionList = action.payload;
    },

    updateQuestionsStatusSuccess(state, action) {
      state.questionsStatus = action.payload;
    },

     // GET Question 
     getQuestionSuccess(state, action) {
      state.isLoading = false;
      state.questionData = action.payload;
    },

    editQuestionStatus(state, action) {
      state.isLoading = false;
      state.editQestionStatus = action.payload;
    },

    //GET AVAILABLE Musher
    getAvailableMusherListSuccess(state, action) {
      state.isLoading = false;
      state.availableMusher = action.payload;
    },

    // GET Musher BY ID
    getMusherSuccess(state, action) {
      state.isLoading = false;
      state.statusLoading = false;
      state.musher = action.payload;
    },

    // UPDATE Musher
    updateMusherSuccess(state, action) {
      state.isLoading = false;
      state.isMusherCreated = true;
    },

    resetMusherState(state) {
      state.isMusherDeleted = false;
      state.isMusherCreated = false;
    },
    // GET Musher REQUEST LIST
    getMusherRequestListSuccess(state, action) {
      state.isLoading = false;
      state.musherRequestList = action.payload;
    },


    updateMusherStatusSuccess(state, action) {
      state.musherStatus = action.payload;
    },
    updateMusherAvailabilitySuccess(state, action) {
      state.musherAvailabilityStatus = action.payload;
    },

    getClientMusherSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
//-------------------------------------------------------------------------------------
export function getMusherList({ page, size, userGPTStatus, UserPhoneNum, OrderByColumn, OrderByDesc }: { page: number | null; size: number|null, userGPTStatus:number|string|null, UserPhoneNum:string|null, OrderByColumn:string|null, OrderByDesc: boolean | null }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('AppAdmin/GPTUserList', {
        params: {
          page,
          size,
          userGPTStatus:userGPTStatus?userGPTStatus:null,
          UserPhoneNum:UserPhoneNum?UserPhoneNum:null,
          OrderByColumn:OrderByColumn? OrderByColumn : "CreatedDate",
          OrderByDesc:OrderByDesc
        }
      });

      dispatch(slice.actions.getMusherListSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClientMusher({ clientId }: { clientId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(`/Clients/${clientId}`);
      var raw = JSON.stringify({
        "userId": clientId
      });
      const response = await fetch(`https://shwrachatgptapi.azurewebsites.net/api/questions/GetQuestionAnswerByUserId`, {
        method: 'POST',
        body: raw,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      dispatch(slice.actions.getClientMusherSuccess(data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getQuestionsList({ page, size, questionId, isAccepted, orderBy, sortBy }: { page: number | null; size: number | null, questionId: string | null, isAccepted: string | null | boolean | number , orderBy: string | null, sortBy: string | null }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var raw = JSON.stringify({
        "pageSize": size,
        "pageNumber": page,
        "mobileNo":questionId?questionId: null,
        "isAccepted":isAccepted,
        "orderBy": orderBy?orderBy : "createdDate",
        "sortBy":sortBy?sortBy: "desc"
      });
      const response = await fetch(`https://shwrachatgptapi.azurewebsites.net/api/questions/GetAllQuestion`, {
        method: 'POST',
        body: raw,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      dispatch(slice.actions.getQuestionListSuccess(data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUpdateQuestionsStatus(data: {}) {
  return dispatch(slice.actions.updateQuestionsStatusSuccess(data));
}

export function getQuestionDetails({ id }: { id: string}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`https://shwrachatgptapi.azurewebsites.net/api/questions/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      dispatch(slice.actions.getQuestionSuccess(data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editQuestion({ id, correctAnswer }: { id: string, correctAnswer:string}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var raw = JSON.stringify({
        "questionId": id,
        "correctAnswer": correctAnswer
      });
      const response = await fetch(`https://shwrachatgptapi.azurewebsites.net/api/questions/CorrectAnswer`, {
        method: 'POST',
        body: raw,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if(data?.status !== "Success")
      {
        dispatch(slice.actions.editQuestionStatus({ status: 'error', error: data })); 
      }
      else
      dispatch(slice.actions.editQuestionStatus({ status: 'success', ...data }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function setQuestionDetails(data: any) {
  return dispatch(slice.actions.getQuestionSuccess(data));
}

export function resetQuestionDetails(data: any) {
  return dispatch(slice.actions.editQuestionStatus({ status: '', ...data }));
}
//----------------------------------------------------------------------------


export function getAvailableMusherList({ userGPTStatus }: { userGPTStatus: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/GPTUserList/userGPTStatus', {
        params: {
          userGPTStatus
        }
      });
      dispatch(slice.actions.getAvailableMusherListSuccess(response.data?.result))

    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function setMusher(data: any) {
  return dispatch(slice.actions.getMusherSuccess(data));
}


export function setUpdateMusherStatus(data: {}) {
  return dispatch(slice.actions.updateMusherStatusSuccess(data));
}


//For changing is Musher available 

export function updateMusherAvailability({ UserId }: { UserId: string }) {
  return async () => {
    dispatch(slice.actions.statusLoading());
    try {

      const params = {
        UserId
      }
      const response = await axios.post(`AppAdmin/GPTUserApproveByAdmin?UserId=${UserId}`, null, { params });

      dispatch(
        slice.actions.updateMusherAvailabilitySuccess(response?.data)
      );

    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.updateMusherAvailabilitySuccess({ status: false })
      );
    }
  };
}

//For changing is Musher bulk available
export function updateBulkMusherAvailability({ UsersId }: { UsersId: ReadonlyArray<string>  }) {
  return async () => {
    dispatch(slice.actions.statusLoading());
    try {
      let config = {
        maxBodyLength: Infinity,
        headers: { 
          'Content-Type': 'application/json', 
        },
      };
      const response = await axios.post(`AppAdmin/GPTUserApproveByAdminBulk`, UsersId, config);

      dispatch(
        slice.actions.updateMusherAvailabilitySuccess(response?.data)
      );

    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.updateMusherAvailabilitySuccess({ status: false })
      );
    }
  };
}

export function setUpdateMusherAvailability(data: {}) {
  return dispatch(slice.actions.updateMusherAvailabilitySuccess(data));
}
