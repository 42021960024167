import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
  Box,
  Stack,
  Select,
  MenuItem,
  Pagination,
  TextField,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
// redux
import { RootState, useDispatch, useSelector } from "../../../redux/store";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import LoadingTable from "../../../components/LoadingTable";
import EmptyTable from "../../../components/EmptyTable";
import "moment/locale/ar";
import { useTheme } from "@mui/material/styles";
import HeaderWithoutTabs from "components/_dashboard/HeaderWithoutTabs";
import { CheckCircleRounded, PendingRounded } from "@mui/icons-material";
import TableDownloader from "../TableDownloader";
import { CircularProgress } from "@mui/material";
//icons
import closeFill from "@iconify/icons-eva/close-fill";
import searchFill from "@iconify/icons-eva/search-fill";
import usePageFilter from "hooks/usePageFilter";
import { PATH_DASHBOARD } from "routes/paths";
import { deleteB2b, editB2b, getB2bCompaniesList } from "redux/slices/b2b";
import plusFill from "@iconify/icons-eva/plus-fill";
import B2bCompanyCreate from "./B2bCompanyCreate";
import { IB2bCompaniesList, B2bCompanies } from "../../../@types/b2b";
import B2bListHead from "./B2bListHead";
import B2bMoreMenu from "components/_dashboard/b2b/B2bMoreMenu";
import ChangeStatusModal from "./ChangeStatusModal";
import { useSnackbar } from "notistack";
import useLocales from "hooks/useLocales";
import useAuth from "hooks/useAuth";
import { getAllowedActionsByModuleName } from "redux/slices/permission";

const TableLink = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const TABLE_HEAD = [
  { id: "number", label: "number", alignRight: false },
  { id: "shortName", label: "short name", alignRight: false },
  { id: "fullName", label: "full name", alignRight: false },
  { id: "description", label: "description", alignRight: false },
  { id: "crNumber", label: "tax number", alignRight: false },
  { id: "contactNo", label: "contact number", alignRight: false },
  { id: "contactEmail", label: "email", alignRight: false },
  { id: "address", label: "address", alignRight: false },
  { id: "lattitude", label: "latitude", alignRight: false },
  { id: "longitude", label: "longitude", alignRight: false },
  { id: "isActive", label: "status", alignRight: false },
  { id: "", alignRight: false },
];

export default function B2bList() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, setPage, statusFilter, setStatusFilter } = usePageFilter();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { b2bCompaniesList, isLoading, isB2bDeleted, updateB2b } = useSelector(
    (state: RootState) => state.b2b
  );
  const [b2b, setB2bs] = useState<IB2bCompaniesList>({
    list: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
  });

  const [changingAvailability, setChangingAvailability] = useState(false);
  const [changingAvailID, setChangingAvailD] = useState<any>(null);
  // to reload musher list after status change
  const [trackerForReload, setTrackerForReload] = useState(false);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [order, setOrder] = useState<"asc" | "desc" | "">("");
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const [openCompanyForm, setOpenCompanyForm] = useState(false);

  const [selectedB2b, setselectedB2b] = useState<B2bCompanies>({
    id: "",
    shortName: "",
    description: "",
    fullName: "",
    crNumber: "",
    contactNo: "",
    contactEmail: "",
    address: "",
    lattitude: 0,
    longitude: 0,
    isActive: true,
  });

  const [b2bId, setB2bId] = useState("");

  const [modalType, setModalType] = useState<"delete" | "">("");

  const [openStatusModal, setOpenStatusModal] = useState(false);

  const { translate } = useLocales();

  const handleRequestSort = (isAsc: any, property: any) => {
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (b2bCompaniesList) {
      setB2bs({
        ...b2bCompaniesList,
        list: b2bCompaniesList?.list,
      });
    }
  }, [b2bCompaniesList]);

  //-------------------------------------------

  useEffect(() => {
    if (searchInput) {
      dispatch(
        getB2bCompaniesList({
          page: page + 1,
          limit: rowsPerPage,
          SearchKeyword: searchInput ? searchInput : null,
          orderBy: "CreatedDate",
          sort: order === "asc" ? "asc" : "desc",
        })
      );
    } else
      dispatch(
        getB2bCompaniesList({
          page: page + 1,
          limit: rowsPerPage,
          SearchKeyword: searchInput ? searchInput : null,
          orderBy: "CreatedDate",
          sort: order === "asc" ? "asc" : "desc",
        })
      );
  }, [
    dispatch,
    searchInput,
    page,
    rowsPerPage,
    statusFilter,
    trackerForReload,
    order,
  ]);

  useEffect(() => {
    dispatch(
      getB2bCompaniesList({
        page: page + 1,
        limit: rowsPerPage,
        SearchKeyword: searchInput ? searchInput : null,
        orderBy: "CreatedDate",
        sort: order === "asc" ? "asc" : "desc",
      })
    );
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchInput,
    order,
    isB2bDeleted,
    updateB2b,
  ]);

  const isB2bEmpty = !isLoading && b2b?.list?.length === 0;

  //Design for different rows
  const RowDesignA = {
    bgcolor: theme.palette.mode === "dark" ? "#161C24" : "#ffe9dc",
    "&.MuiTableRow-hover:hover": {
      bgcolor: theme.palette.mode === "dark" ? "#29343F" : "#F6F7F8",
    },
  };

  // to change musher availability status

  const handleClickSerachAction = () => {
    if (searchInput) {
      setSearchInput("");
    } else {
      return;
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    if (isB2bDeleted) {
      enqueueSnackbar("تم حذف الشركة بنجاح", { variant: "success" });
    }
  }, [isB2bDeleted, enqueueSnackbar]);
  const AvailabilityHandler = (row: B2bCompanies) => {
    setChangingAvailability(true);
    dispatch(
      editB2b({
        id: row.id,
        shortName: row.shortName,
        description: row.description,
        fullName: row.fullName,
        crNumber: row.crNumber,
        contactNo: row.contactNo,
        contactEmail: row.contactEmail,
        address: row.address,
        lattitude: row.lattitude,
        longitude: row.longitude,
        isActive: !row.isActive,
      })
    );
  };

  // useEffect(() => {
  //   if (updateB2b?.status === 'success') {
  //     enqueueSnackbar('تم تعديل حالة الشركة بنجاح', {
  //       variant: 'success'
  //     });
  //   }
  // }, [enqueueSnackbar, updateB2b]);

  const { user } = useAuth();
  const { actionsList } = useSelector((state: RootState) => state.permission);

  useEffect(() => {
    dispatch(getAllowedActionsByModuleName(user?.id, "companies"));
  }, [dispatch, user?.id]);

  function isActionAllowed(actioName: string) {
    if (user?.role === "Admin" || actionsList?.includes(actioName)) return true;
    return false;
  }

  return (
    <Page title="شركات: قائمة | شورى">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderWithoutTabs Heading={translate("companies")} />

        <Stack
          gap={2}
          mb={4}
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          {/* //--------------------- CODE FOR FILTER-----------------------------\\ */}

          <Box sx={{ marginY: "1rem", width: { xs: "100%", md: "40%" } }}>
            <TextField
              sx={{
                "& fieldset": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "#161C24 !important"
                      : "#fff !important",
                  borderColor: "text.primary !important",
                },
              }}
              variant="outlined"
              fullWidth
              placeholder={translate("search")}
              value={searchInput}
              onChange={(e) => setSearchInput(e?.target?.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => handleClickSerachAction()}>
                      <Icon
                        icon={searchInput ? closeFill : searchFill}
                        width={20}
                        height={20}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />{" "}
          </Box>

          <Stack
            direction="row"
            flexGrow={1}
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            {isActionAllowed("Create") && (
              <Button
                sx={{ ml: "auto" }}
                variant="contained"
                startIcon={<Icon icon={plusFill} width={20} height={20} />}
                onClick={() => {
                  setB2bId("");
                  setOpenCompanyForm(true);
                }}
              >
                {translate("new company")}
              </Button>
            )}
          </Stack>
        </Stack>

        <Card
          sx={{
            border: "1px solid",
            borderColor: "background.tableHead",
            mt: 4,
          }}
        >
          <Scrollbar>
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table>
                <B2bListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={b2b?.list?.length}
                  numSelected={selected.length}
                  handleRequestSort={handleRequestSort}
                />
                {isLoading && <LoadingTable />}
                <TableBody>
                  {b2b?.list?.map((row, index) => {
                    const {
                      shortName,
                      fullName,
                      address,
                      contactEmail,
                      contactNo,
                      crNumber,
                      description,
                      id,
                      isActive,
                      lattitude,
                      longitude,
                    } = row;
                    const isItemSelected = isSelected(row.shortName);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        sx={
                          isActive
                            ? RowDesignA
                            : { bgcolor: "background.mainColor" }
                        }
                        hover
                        key={id}
                        tabIndex={-1}
                      >
                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                          >
                            {page * rowsPerPage + index + 1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TableLink
                            to={`${PATH_DASHBOARD.b2b.root}/${id}/b2b/${page}`}
                          >
                            {shortName}
                          </TableLink>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                          >
                            {fullName}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ padding: "10px 1px" }}>
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                          >
                            {description}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                            noWrap
                          >
                            {crNumber ? crNumber : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                            noWrap
                          >
                            {contactNo ? contactNo : "-"}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                            noWrap
                          >
                            {contactEmail ? contactEmail : "-"}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                            noWrap
                          >
                            {address ? address : "-"}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                            noWrap
                          >
                            {lattitude ? lattitude : "-"}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            sx={{
                              color: isActive
                                ? theme.palette.mode === "dark"
                                  ? "white"
                                  : "black"
                                : " ",
                            }}
                            noWrap
                          >
                            {longitude ? longitude : "-"}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          {changingAvailability && id === changingAvailID ? (
                            <CircularProgress size={15} />
                          ) : isActive ? (
                            <Tooltip
                              onClick={() =>
                                isActionAllowed("UpdateStatus") &&
                                AvailabilityHandler(row)
                              }
                              title={null}
                            >
                              <CheckCircleRounded
                                sx={{ cursor: "pointer" }}
                                color="success"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              onClick={() =>
                                isActionAllowed("UpdateStatus") &&
                                AvailabilityHandler(row)
                              }
                              title={null}
                            >
                              <PendingRounded
                                sx={{
                                  color: "text.disabled",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            gap={2}
                          >
                            <B2bMoreMenu
                              canDelete={isActionAllowed("Delete")}
                              canEdit={isActionAllowed("Update")}
                              onDelete={() => {
                                setOpenStatusModal(true);
                                setModalType("delete");
                                setselectedB2b({
                                  shortName: shortName,
                                  id: id,
                                  fullName: fullName,
                                  address: address,
                                  contactEmail: contactEmail,
                                  contactNo: contactNo,
                                  crNumber: crNumber,
                                  description: description,
                                  isActive: isActive,
                                  lattitude: lattitude,
                                  longitude: longitude,
                                });
                                // handleDeleteB2b(id)
                              }}
                              userName={id}
                              setDisplay={setOpenCompanyForm}
                              setB2bId={setB2bId}
                              b2b={row}
                              setselectedB2b={setselectedB2b}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isB2bEmpty && (
                  <EmptyTable message="لا يوجد شركات فى الوقت الحالى" />
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Stack
            sx={{
              padding: "1rem",
              bgcolor: "background.mainColor",
              gap: "6px",
            }}
            direction={{ xs: "column", sm: "row-reverse" }}
            justifyContent="space-between"
          >
            <Stack
              direction={"row-reverse"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Pagination
                defaultPage={1}
                page={page + 1}
                onChange={(event, newPage) => {
                  if (newPage !== null)
                    // setPage(newPage - 1)
                    navigate(`/dashboard/b2b/list?/${newPage - 1}`, {
                      replace: true,
                    });
                }}
                count={Math.ceil(b2b?.totalCount / rowsPerPage) || 0}
                variant="outlined"
                shape="rounded"
              />
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(0);
                }}
                sx={{
                  height: 40,
                  width: 80,
                  "& fieldset": {
                    borderColor: "background.tableHead",
                  },
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Stack>
            <TableDownloader disabled={true} />
          </Stack>
        </Card>
      </Container>

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { xs: "80%", md: "60%" },
            },
          },
        }}
        open={openCompanyForm}
      >
        <DialogContent sx={{ paddingTop: 0 }}>
          <B2bCompanyCreate
            setDisplay={setOpenCompanyForm}
            b2b={selectedB2b}
            name={b2bId}
            AvailabilityHandler={AvailabilityHandler}
          />
        </DialogContent>
      </Dialog>
      <ChangeStatusModal
        type={modalType}
        open={openStatusModal}
        setOpen={setOpenStatusModal}
        b2b={selectedB2b}
      />
    </Page>
  );
}
