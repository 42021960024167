import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import suspendFill from '@iconify/icons-ic/block';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import SvgIconStyle from 'components/SvgIconStyle';
import { B2bCompanies } from '../../../@types/b2b';

type B2bMoreMenuProps = {
  onDelete: VoidFunction;
  userName: string;
  setDisplay:Function;
  setB2bId:Function;
  b2b: null| B2bCompanies;
  setselectedB2b:Function;
  canDelete?:boolean;
  canEdit?:boolean;
};

export default function B2bMoreMenu({
  onDelete,
  userName,
  setDisplay,
  setB2bId,
  setselectedB2b,
  b2b,
  canDelete=true,
  canEdit=true
}: B2bMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const B2bEditHandler = ()=> {
    setDisplay(true);
    setselectedB2b({
      shortName: b2b?.shortName,
      id: b2b?.id,
      fullName:b2b?.fullName,
      address:b2b?.address,
      contactEmail:b2b?.contactEmail,
      contactNo:b2b?.contactNo,
      crNumber:b2b?.crNumber,
      description:b2b?.description,
      isActive:b2b?.isActive,
      lattitude:b2b?.lattitude,
      longitude:b2b?.longitude
    });
    setB2bId(userName)
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
       {canDelete && <MenuItem
          onClick={() => {
            onDelete();
            setIsOpen(false);
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="حذف" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

       {canEdit && <MenuItem
          onClick={B2bEditHandler}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="تحديث" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}


      </Menu>
    </>
  );
}
