import * as Sentry from "@sentry/react";
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import {
    EmployeeState 
  } from '../../@types/employee'; 
// @types

// ----------------------------------------------------------------------


const initialState: EmployeeState = {
  isLoading: false,
  isAssigning: false, 
  error: false,
  EmployeeList:[],
Employee:{},
createEmployeeStatus:{},
updateEmployeeStatus:{},
deleteEmployeeStatus:{},
changeEmployeeStatusSuccess:{},
employeeAvailabilityStatus:{},
};





const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //Newly Added request for Monthly Reports 

    getEmployeeListSuccess(state, action) {
      state.isLoading = false;
      state.EmployeeList = action.payload;
    },
    getEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.Employee = action.payload;
    },
    createEmployeeSuccess( state,action )
    {
      state.isLoading = false;
      state.createEmployeeStatus = action.payload;
    },
    deleteEmployeeSuccess( state,action )
    {
      state.isLoading = false;
      state.deleteEmployeeStatus = action.payload;
    },
    updateEmployeeSuccess( state,action )
    {
      state.isLoading = false;
      state.updateEmployeeStatus = action.payload;
    },
   changeEmployeeStatusSuccess( state,action )
   {
      // state.isLoading = false;
      state.changeEmployeeStatusSuccess = action.payload;
   },
   changeEmployeeAvailabilityStatusSuccess( state,action )
   {
      // state.isLoading = false;
      state.employeeAvailabilityStatus = action.payload;
   }

  }
});






// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
//Newly Added request for Monthly Reports 


export function getEmployeeList( status:number,page:number, size:number) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get('/Employee/GetAllEmployees',{
            params : {
              status:status,
              page : page,
              size : size,
            }
          })
        
          dispatch(slice.actions.getEmployeeListSuccess(response.data.result)); 
          
          
    
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function getEmployee(id:any ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get(`/Employee/GetEmployeeById/${id}`) 
        
          dispatch(slice.actions.getEmployeeSuccess(response.data.result));  
          
          
    
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }
  
  
  export function createEmployee(data: FormData) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/Employee/CreateEmployee`, data);
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.createEmployeeSuccess({ status: 'error', error: response?.data }));
  
        }
        else
        dispatch(slice.actions.createEmployeeSuccess({ status: 'success', ...response?.data?.result }));
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.createEmployeeSuccess({ status: 'error', error: error }));
      }
    };
  }

  export function updateEmployee(id:String,data: FormData) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/Employee/UpdateEmployeeByAdmin/${id}`, data);
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.updateEmployeeSuccess({ status: 'error', error: response?.data }));
  
        }
        else
        dispatch(slice.actions.updateEmployeeSuccess({ status: 'success', ...response?.data?.result }));
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.updateEmployeeSuccess({ status: 'error', error: error }));
      }
    };
  }

  export function deleteEmployee(id: String | number) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.delete(`/Employee/Delete/${id}`);
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.deleteEmployeeSuccess({ status: 'error', error: response?.data }));

        }
        else
          {
            dispatch(slice.actions.deleteEmployeeSuccess({ status: 'success', ...response?.data?.result }));

          }
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.deleteEmployeeSuccess({ status: 'error', error: error }));
      }
    };
  }

  export function changeEmployeeStatus(id:String,status: number) {
    return async () => {
      // dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/Employee/UpdateEmployeeStatus`,null,{
          params:{
            EmployeeId:id,
            Status:status
          }
        });
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.changeEmployeeStatusSuccess({ status: 'error', error: response?.data }));
  
        }
        else
        dispatch(slice.actions.changeEmployeeStatusSuccess({ status: 'success', ...response?.data?.result }));
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.changeEmployeeStatusSuccess({ status: 'error', error: error }));
      }
      return;
    };
  }


  export function markEmployeeAvailability(id:String,isAvailable: boolean) {
    return async () => {
      // dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/Employee/MarkAvailability`,null,{
          params:{
            EmployeeId:id,
            IsAvailable:isAvailable
          }
        });
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.changeEmployeeAvailabilityStatusSuccess({ status: 'error', error: response?.data }));
  
        }
        else
        dispatch(slice.actions.changeEmployeeAvailabilityStatusSuccess({ status: 'success', ...response?.data?.result }));
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.changeEmployeeAvailabilityStatusSuccess({ status: 'error', error: error }));
      }
      return;
      
    };
  }


  export function setUpdateEmployeeStatus(data:any) {
    return dispatch(slice.actions.updateEmployeeSuccess(data))
   }
  
   export function setCreateEmployeeStatus(data:any) {
    return dispatch(slice.actions.createEmployeeSuccess(data))
   }

   export function setDeleteEmployeeStatus(data:any) {
    return dispatch(slice.actions.deleteEmployeeSuccess(data))
   }


//--------------------------------------------------------------------------------------------

 