import axios from 'axios';
// ----------------------------------------------------------------------

//Dev Base Url//
// https://shwraapidevops.azurewebsites.net/api/
//Production Url//
//https://shwra-api-pro-v2.azurewebsites.net/api/


// QA-URL
//https://shwra-qa-be.azurewebsites.net/api


export const URL = process.env.BASE_URL ?? 'https://shwra-api-pro-v2.azurewebsites.net';

export let baseURL = `${URL}/api/`;

export const HUB_URL  = URL; 

export const BLOGS_URL = process.env.BLOG_BASE_URL ?? 'https://blogs-be.azurewebsites.net';

const value = process.env.BASE_URL ? true : false;


// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   baseURL = "https://shwra-proxy.menoufiaevent.com/api/";
// }

const axiosInstance = axios.create({
  // baseURL: 'https://shwraapidevops.azurewebsites.net/api/',
  baseURL,
  //baseURL: 'http://127.0.0.1:8787/api/',
  withCredentials: false
});

//axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

//axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
export const requestHandler = async (request: any) => {
  request.headers['Accept-Language'] = localStorage.getItem('i18nextLng')==='en' ?  'en-US' : 'ar-SA'; 
  return request;
};
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// for images
export const IMAGE_URL = `${URL}/api/Files/DownloadFile?fileName=`;
