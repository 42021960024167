import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { createFileUrl } from 'components/file/FilePreview';
import { scheduleDemoState } from '../../@types/scheduleDemo';

// ----------------------------------------------------------------------

const initialState: scheduleDemoState = {
  isLoading: false,
  error: false,
  scheduleDemoList: [],

};

const slice = createSlice({
  name: 'scheduleDemo',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getScheduleDemoListSuccess(state, action) {
      state.isLoading = false;
      state.scheduleDemoList = action.payload;
    }
  }
});

export default slice.reducer;

//-------------------------------------------------------------------------------------

export function getScheduledDemoList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/B2b/GetScheduledDemo');
      let result = response?.data;
      dispatch(slice.actions.getScheduleDemoListSuccess(result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getPackageDetails(packageId: string | number) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/Package/${packageId}`);
   
//       dispatch(slice.actions.getPackageSuccess({ ...response?.data?.result, image: '' }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function setPackageDetails(data: any) {
//   return dispatch(slice.actions.getPackageSuccess(data));
// }

// export function addPackage(data: Package) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(`/Package/Create`, data);
//       if(response?.data?.status === false)
//       {
        
//         dispatch(slice.actions.addPackageStatus({ status: 'error', error: response?.data })); 
//       }
//       else
//       dispatch(slice.actions.addPackageStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.addPackageStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setAddPackage(data: any) {
//   return dispatch(slice.actions.addPackageStatus(data));
// }

// export function editpackage(data: Package) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.put(`/Package/Update`, data);
//       if(response?.data?.status === false)
//       {
        
//         dispatch(slice.actions.editPackageStatus({ status: 'error', error: response?.data })); 
//       }
//       else
//       dispatch(slice.actions.editPackageStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.editPackageStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setEditPackage(data: any) {
//   return dispatch(slice.actions.editPackageStatus(data));
// }

// export function deletePackage(packageId: any, lastModifiedBy: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.delete(`/Package/Delete`,
//       { 
//         data:{
//         "id":packageId,
//         "isActive":false,
//         "lastModifiedBy":lastModifiedBy
//       }
//      });
//       dispatch(slice.actions.deletePackageStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.deletePackageStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setDeletePackage(data: any) {
//   return dispatch(slice.actions.deletePackageStatus(data));
// }
 