import * as Sentry from '@sentry/react';
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// utils
import { ChatState } from '../../@types/Chat';
import axios from '../../utils/axios';

// @types

// ----------------------------------------------------------------------

const initialState: ChatState = {
  isLoading: false,
  isAssigning: false,
  error: false,
  allMessages: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAllMessagesSuccess(state, action) {
      state.isLoading = false;
      state.allMessages = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;



export function sendMessageByAdmin(FormData: any) {
  return async () => {
    try {
      let response = await axios.post(`/Chat/SendMessage`, FormData);
      return { status: true };
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      return { status: false };
    }
  };
}

export function getAllMessages(id: any, type: number, userId: any,  FromUserId:any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = await axios.get(`Chat/GetMessages`, {
        params: {
          ReferenceId: id,
          ReferenceType: type,
          ToUserId: userId,
          FromUserId:FromUserId
        }
      });
      dispatch(slice.actions.getAllMessagesSuccess({ STATUS: true, ...response.data }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      dispatch(slice.actions.getAllMessagesSuccess({ STATUS: false, error }));
    }
  };
}

//--------------------------------------------------------------------------------------------
