import * as Sentry from '@sentry/react';
import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { LawyerState } from '../../@types/lawyer';



const initialState: LawyerState = {
  isLoading: false,
  statusLoading: false,
  passwordResetMessage: '',
  error: false,
  lawyerList: {
    count: 0,
    lawyersDtos: [],
    page: 0,
    size: 0
  },
  lawyerRequestList: [],
  lawyer: null,
  isOpenModal: false,
  isLawyerDeleted: false,
  isLawyerCreated: false,
  lawyerStatus: {}, 
  lawyerAvailabilityStatus:{},  
  availableLawyer : [] ,
  

};

const slice = createSlice({
  name: 'lawyer',
  initialState,
  reducers: {
    resetPasswordSuccess(state, action) {
      state.isLoading = false;
      state.error = false;
      state.passwordResetMessage = action.payload.message;
    },
    resetPasswordFailure(state, action) {
      state.isLoading = false;
      state.error = true;
      state.passwordResetMessage = action.payload.message;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    statusLoading(state) {
      state.statusLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET LAWYER LIST
    getLawyerListSuccess(state, action) {
      state.isLoading = false;
      state.lawyerList = action.payload;
    },

        //GET AVAILABLE LAWYER
        getAvailableLawyerListSuccess(state,action)
        {
          state.isLoading = false;
          state.availableLawyer = action.payload;
        },

    // GET LAWYER BY ID
    getLawyerSuccess(state, action) {
      state.isLoading = false;
      state.statusLoading = false;
      state.lawyer = action.payload;
    },
    // CREATE LAWYER
    createLawyerSuccess(state, action) {
      state.isLoading = false;
      state.isOpenModal = false;
      state.isLawyerCreated = true;
      state.lawyerList = {
        ...state.lawyerList,
        lawyersDtos: [...state.lawyerList.lawyersDtos, action.payload]
      };
    },
    // UPDATE LAWYER
    updateLawyerSuccess(state, action) {
      state.isLoading = false;
      state.isLawyerCreated = true;
    },
    // DELETE LAWYER
    deleteLawyerSuccess(state, action) {
      state.isLawyerDeleted = true;
      state.isLoading = false;
      const deleteLawyer = filter(
        state.lawyerList.lawyersDtos,
        (layer) => layer.lawyerId !== action.payload
      );
      state.lawyerList = {
        ...state.lawyerList,
        lawyersDtos: deleteLawyer
      };
    },
    resetLawyerState(state) {
      state.isLawyerDeleted = false;
      state.isLawyerCreated = false;
    },
    // GET LAWYER REQUEST LIST
    getLawyerRequestListSuccess(state, action) {
      state.isLoading = false;
      state.lawyerRequestList = action.payload;
    },
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },
    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

    updateLawyerStatusSuccess(state, action) {
      state.lawyerStatus = action.payload;
    },
    updateLawyerAvailabilitySuccess(state, action) {
      state.lawyerAvailabilityStatus = action.payload;
    },
    resetHasError(state)
    {
      state.isLoading = false;
      state.error = false;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { openModal, closeModal,  resetPasswordSuccess, resetPasswordFailure } = slice.actions;
//-------------------------------------------------------------------------------------
export function resetLawyerPassword(lawyerId: string, newPassword: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Account/ResetPasswordByAdmin', null, {
        params: {
          UserId: lawyerId,
          Password: newPassword,
        },
      });

      if (response.data.status) {
        dispatch(resetPasswordSuccess({ message: 'Password changed successfully' }));
      } else {
        dispatch(resetPasswordFailure({ message: 'Error changing password (API response)' }));
      }
    } catch (error) {
      console.error('Error resetting lawyer password:', error);
      dispatch(resetPasswordFailure({ message: 'Error changing password (network error)' }));
    }
  };
}

//-------------------------------------------------------------------------------------
export function getLawyerList({ page, size }: { page: number; size: number; }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Lawyers', {
        params: {
          page,
          size
        }
      });

      dispatch(slice.actions.getLawyerListSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------


export function getAvailableLawyerList({ AppointmentId }: { AppointmentId:string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Lawyers/getAvailableLawyers', {
        params: {
          AppointmentId
        }
      });
      dispatch(slice.actions.getAvailableLawyerListSuccess(response.data?.result))
      
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------
export function getLawyer(lawyerId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.statusLoading());
    try {
      const response = await axios.get(`/Lawyers/${lawyerId}`);
      dispatch(slice.actions.getLawyerSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setLawyer(data: any) {
  return dispatch(slice.actions.getLawyerSuccess(data));
}
export function createLawyer(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Lawyers', params);

      if(response?.data?.status===false)
      {
        dispatch(slice.actions.hasError(response?.data));
      }
      else{
      const createdLawyer = await axios.get(`/Lawyers/${response.data?.result}`);
      dispatch(slice.actions.createLawyerSuccess(createdLawyer.data?.result));
      dispatch(slice.actions.resetLawyerState());
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateLawyer(params: any, lawyerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/Lawyers/UpdateLawyerByAdmin/${lawyerId}`, params);
      if(response?.data?.status===false){
      dispatch(slice.actions.hasError(response?.data));

      }
      else{
      dispatch(slice.actions.updateLawyerSuccess(response.data?.result));
      dispatch(slice.actions.resetLawyerState());
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteLawyer({ lawyerId }: { lawyerId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/Lawyers/${lawyerId}`);
      if(response?.data?.status===false){
        dispatch(slice.actions.hasError(response?.data));
  
        }
        else{
      dispatch(slice.actions.deleteLawyerSuccess(lawyerId));
      dispatch(slice.actions.resetLawyerState());
        }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getLawyerRequests({ lawyerId }: { lawyerId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Lawyers/${lawyerId}/requests`);
      if(response?.data?.status===false){
        dispatch(slice.actions.hasError(response?.data));
  
        }
        else{
      dispatch(slice.actions.getLawyerRequestListSuccess(response.data?.result?.requests));
        }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateLawyerStatus(lawyerId: string, statusId: number) {
  return async () => {
    dispatch(slice.actions.statusLoading());
    try {
      const response = await axios.put(`/Lawyers/ChangeLawyerStatus/${lawyerId}/${statusId}`); 
      dispatch(
        slice.actions.updateLawyerStatusSuccess({ success: true, message: 'Updated Successfully' })
      );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUpdateLawyerStatus(data: {}) {
  return dispatch(slice.actions.updateLawyerStatusSuccess(data));
}

export function suspendLawyer(lawyerId: string, suspendedReason: string,suspendedFrom: string,suspendedTo: string) {
  return async () => {
    dispatch(slice.actions.statusLoading());
    try {
      const response = await axios.put(`/Lawyers/SuspendedLawyer`,{
        lawyerId:lawyerId,
        suspendedReason:suspendedReason,
        suspendedFrom:suspendedFrom,
        suspendedTo:suspendedTo
      }
     ); 
      dispatch(
        slice.actions.updateLawyerStatusSuccess({ success: true, message: 'Updated Successfully' })
      );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelSuspendLawyer(lawyerId: string) {
  return async () => {
    dispatch(slice.actions.statusLoading());
    try {
      const response = await axios.put(`/Lawyers/CancelLawyerSuspention`,{
        lawyerId:lawyerId
      }
     ); 
      dispatch(
        slice.actions.updateLawyerStatusSuccess({ success: true, message: 'Updated Successfully' })
      );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSuspendLawyer(data: {}) {
  return dispatch(slice.actions.updateLawyerStatusSuccess(data));
}


//For changing is lawyer available 

export function updateLawyerAvailability( { LawyerId,IsAvailable } : { LawyerId:string,IsAvailable:boolean }) {
  return async () => {
    dispatch(slice.actions.statusLoading()); 
    try {

      const params = {
        LawyerId,
        IsAvailable
      }
      const response = await axios.put(`/Lawyers/MarkAvailability`,null,{ params });    
      
      
      dispatch(
        slice.actions.updateLawyerAvailabilitySuccess(response?.data)    
      );  
      
    } catch (error) {
      Sentry.captureException(error); 
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.updateLawyerAvailabilitySuccess({ status:false })      
      );  
    }
  };
}

export function setUpdateLawyerAvailability(data: {}) {
  return dispatch(slice.actions.updateLawyerAvailabilitySuccess(data)); 
}

export function resetError(data: {}) {
  return dispatch(slice.actions.resetHasError()); 
}